import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { combineClassNames } from '@utils/combineClassNames';
import { AsyncList } from '@common/components/list';
import Icon from '@common/components/icon';
import Spinner from '@common/components/spinner';
import { TableRow } from './table-row';
import { getSize } from './utils';
import type { LooseObject } from '@common/types/util-types';
import type { AsyncListProps, AsyncDataProps } from '../list';
import type { SortDirection } from '@common/types/values';
import dndManager from '@common/utils/dnd-manager';

type Column = {
  label?: React.ReactNode;
  className?: string;
  size?: string | number;
  sort_key?: string;
};

type ShowMoreComponentProps = {
  onShowMore: () => Promise<void>,
  isFetching?: boolean,
};

const TableShowMoreComponent = ({ onShowMore, isFetching }: ShowMoreComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className="Table__ShowMore" onClick={!isFetching ? onShowMore : undefined} role="button">
      {isFetching ? <Spinner centered /> : t('common:table_show_more')}
    </div>
  );
};

type TableProps<
ItemType,
RowProps,
APIType,
Filter,
> = Omit<AsyncListProps<ItemType, RowProps, APIType, Filter>, 'renderRow'> & {
  columns: (Column & { sort_key?: string })[];
  // @ts-expect-error
  data: AsyncDataProps<APIType, (Filter & { sort_key?: string, order?: SortDirection })>;
  items?: ItemType[];
  renderRow: (props: { item: ItemType, index: number } & RowProps) => React.ReactNode[];
  initialSortState?: { key: string, direction: SortDirection };
  sortingDisabled?: boolean;
  sortingHandler?: (items: ItemType[], sortKey: string | undefined, sortDirection: SortDirection) => ItemType[];
  onChangeSorting?: (sortKey: string | undefined, sortDirection: SortDirection) => void;
  hideHeader?: boolean;
  borderless?: boolean;
  selectedRow?: any;
  onChangeOrder?: (targetId: string, itemId: string) => void;
  onDrop?: () => Promise<void>;
  ActionComponent?: React.ComponentType<{ item: ItemType, refetchItems: () => Promise<unknown> } & any>;
};

export function AsyncTable<
  ItemType extends LooseObject<{ id: string }>,
  APIType extends LooseObject<{ id: string }>,
  RowProps extends Record<string, unknown>,
  Filter extends Record<string, unknown>,
>({
  columns,
  data,
  items,
  renderRow,
  rowProps,
  initialSortState,
  containerClassName,
  selectedRow,
  hideHeader,
  borderless,
  sortingDisabled,
  sortingHandler = (newItems: ItemType[]) => newItems,
  onChangeSorting,
  onChangeOrder,
  onDrop,
  ActionComponent,
  ...listProps
}: TableProps<ItemType, RowProps, APIType, Filter>) {
  /* eslint-disable react/destructuring-assignment */
  const [sort, setSort] = React.useState<string | undefined>(initialSortState?.key);
  const [order, setOrder] = React.useState<SortDirection>(initialSortState?.direction || 'desc');

  const classNames = combineClassNames('Table', containerClassName, {
    'Table--borderless': borderless,
  });

  const newData = data && { ...data };

  // If table is sortable we add sort_key and order to the filter object
  if (newData && sort) {
    newData.filter = { ...newData.filter, sort_key: sort, order };
  }

  const sortedItems = useMemo(
    () => items && sortingHandler(items, sort, order),
    [items, sortingHandler, sort, order],
  );

  const handleChangeSorting = ((column: Column) => {
    const newDirection = order === 'asc' ? 'desc' : 'asc';
    if (!column.sort_key || sortingDisabled) return undefined;

    return () => {
      onChangeSorting?.(column.sort_key, newDirection);

      if (column.sort_key !== sort) return setSort(column.sort_key);

      setOrder(newDirection);
    };
  });

  return (
    <DndProvider manager={dndManager}>
      <AsyncList
        containerClassName={classNames}
        items={sortedItems}
        header={(
          <div className={`Table__Row Table__Header${hideHeader ? ' Table__Header--hidden' : ''}`}>
            {onChangeOrder && <div className="Table__Cell Table__Action" />}
            {columns.map((column, index) => (
              <div
                key={column.label?.toString() || index}
                role="button"
                className={combineClassNames('Table__Cell', { 'Table__Cell--sortable': !!column.sort_key && !sortingDisabled })}
                style={{ width: getSize(column.size, columns.length) }}
                onClick={handleChangeSorting?.(column)}
              >
                {column.sort_key && sort === column.sort_key && (
                  <div className="Table__Sort">
                    <Icon type={order === 'desc' ? 'expand_more' : 'expand_less'} />
                  </div>
                )}
                {!hideHeader && (
                  <h5>
                    {column.label}
                  </h5>
                )}
              </div>
            ))}
            {ActionComponent && <div className="Table__Cell Table__Action" />}
          </div>
        )}
        renderRow={TableRow}
        rowProps={{
          rowProps,
          columns,
          selectedRow,
          createCells: renderRow,
          onChangeOrder,
          onDrop,
          ActionComponent,
        }}
        {...listProps}
        data={newData}
        ShowMoreComponent={TableShowMoreComponent}
      />
    </DndProvider>
  );
}
