import React, { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';

import { PureList } from '@common/components/list';
import { TableRow } from './table-row';
import Icon from '../icon';

import { combineClassNames } from '@utils/combineClassNames';
import { getSize } from './utils';
import dndManager from '@common/utils/dnd-manager';
import type { LooseObject } from '@common/types/util-types';
import type { PureListProps } from '../list';
import { SortDirection } from '@common/types/values';

type Column<ItemType> = {
  label?: React.ReactNode;
  className?: string;
  size?: string | number;
  sort_key?: keyof ItemType;
};

type TableProps<
ItemType,
RowProps,
> = Omit<PureListProps<ItemType, RowProps>, 'renderRow'> & {
  columns: Column<ItemType>[];
  items?: ItemType[];
  renderRow: (props: { item: ItemType, index: number } & RowProps) => React.ReactNode[];
  hideHeader?: boolean;
  borderless?: boolean;
  selectedRow?: any;
  onChangeOrder?: (targetId: string, itemId: string) => void;
  onDrop?: () => Promise<void>;
  ActionComponent?: React.ComponentType<{ item: ItemType, refetchItems: () => Promise<unknown> } & RowProps>;
  initialSortState?: { key: keyof ItemType, direction: SortDirection };
  sortingDisabled?: boolean;
  sortingHandler?: (items: ItemType[], sortKey: keyof ItemType, sortDirection: SortDirection) => ItemType[];
  onChangeSorting?: (sortKey: keyof ItemType, sortDirection: SortDirection) => void;
};

export function Table<
  ItemType extends LooseObject<{ id: string }>,
  RowProps extends Record<string, unknown>,
>(props: TableProps<ItemType, RowProps>) {
  const {
    columns,
    items,
    renderRow,
    rowProps,
    containerClassName,
    selectedRow,
    hideHeader,
    borderless,
    onChangeOrder,
    onDrop,
    ActionComponent,
    sortingDisabled,
    initialSortState,
    sortingHandler = (newItems: ItemType[]) => newItems,
    onChangeSorting,
    ...listProps
  } = props;
  const classNames = combineClassNames('Table', containerClassName, {
    'Table--borderless': borderless,
  });

  const [sort, setSort] = useState<keyof ItemType | undefined>(initialSortState?.key);
  const [order, setOrder] = useState<SortDirection>(initialSortState?.direction || 'desc');

  const sortedItems = useMemo(
    () => (items && sort && sortingHandler(items, sort, order)) || items,
    [items, sortingHandler, sort, order],
  );

  const handleChangeSorting = ((column: Column<ItemType>) => {
    const newDirection = order === 'asc' ? 'desc' : 'asc';
    if (!column.sort_key || sortingDisabled) return undefined;
    return () => {
      onChangeSorting?.(column.sort_key!, newDirection);
      if (column.sort_key !== sort) return setSort(column.sort_key);
      setOrder(newDirection);
    };
  });

  return (
    <DndProvider manager={dndManager}>
      <PureList
        containerClassName={classNames}
        items={sortedItems}
        header={(
          <div className={`Table__Row Table__Header${hideHeader ? ' Table__Header--hidden' : ''}`}>
            {onChangeOrder && <div className="Table__Cell Table__Action" />}
            {columns.map((column, index) => (
              <div
                key={column.label?.toString() || index}
                role="button"
                className={combineClassNames('Table__Cell', { 'Table__Cell--sortable': !!column.sort_key && !sortingDisabled })}
                style={{ width: getSize(column.size, columns.length) }}
                onClick={(!sortingDisabled && handleChangeSorting?.(column)) || undefined}
              >
                {column.sort_key && sort === column.sort_key && (
                  <div className="Table__Sort">
                    <Icon type={order === 'desc' ? 'expand_more' : 'expand_less'} />
                  </div>
                )}
                {!hideHeader && (
                  <h5>
                    {column.label}
                  </h5>
                )}
              </div>
            ))}
            {ActionComponent && <div className="Table__Cell Table__Action" />}
          </div>
        )}
        renderRow={TableRow}
        rowProps={{
          rowProps,
          columns,
          selectedRow,
          createCells: renderRow,
          onChangeOrder,
          onDrop,
          ActionComponent,
        }}
        {...listProps}
      />
    </DndProvider>
  );
}
